import React from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";
import useMyClaims from "../../services/useMyClaims";
import useMyStatus from "../../services/useMyStatus";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import EmailSettingsTable from "../EmailSettingsTable";
import Grid from "@mui/material/Grid2";

const ProfilePage: React.FC = () => {
  const { data } = useMyStatus();
  const { isAuthenticated, isLoading } = useAuth0();
  const userclaims = useMyClaims();
  const adminConsentUrl =
    "https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=ae2d453e-3db2-410a-966c-b229e6425534&scope=https://graph.microsoft.com/.default&redirect_uri=" +
    window.location.origin +
    "/adminconsent";

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <TopMenuBar />
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <h1>Profile Page</h1>
        {isAuthenticated && (
          <>
            <Grid container spacing={2}>
              <Grid size={6}>
                <p>
                  <strong>Name: </strong>
                  {userclaims.data
                    ? userclaims.data["http://empowering.cloud/displayName"]
                    : ""}
                </p>
                <p>
                  <strong>Email: </strong>
                  {userclaims.data ? userclaims.data.email : ""}
                </p>
                <p>
                  <strong>Account: </strong>{" "}
                  {(data?.organisationName != null
                    ? data.organisationName
                    : "") +
                    " (" +
                    data?.organisationId +
                    ")"}
                </p>
                <p>
                  <strong>Account ChangePilot License:</strong>{" "}
                  {data?.membershipType}{" "}
                  {data?.daysLeftText !== ""
                    ? "(" + data?.daysLeftText + ")"
                    : null}
                </p>
                <p>
                  <strong>Tenant connection status:</strong>{" "}
                  {data?.isTenantIdStored
                    ? "Tenant Specific Feed"
                    : "Standard Feed"}
                </p>
                {data && !data?.isTenantIdStored ? (
                  <Button
                    href={adminConsentUrl}
                    target="_blank"
                    variant="contained"
                    color="primary"
                  >
                    View permissions and connect to M365 tenant
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
              {/* <Grid size={6}>
                <Paper elevation={3} sx={{ padding: 1 }}>
                  <h4>Technical Troubleshooting Information</h4>
                  <p>
                    <strong>Identity:</strong>
                    {userclaims.data
                      ? userclaims.data["http://empowering.cloud/displayName"]
                      : ""}
                  </p>
                  <p>
                    <strong></strong>
                  </p>
                </Paper>
              </Grid> */}
            </Grid>
            <p>
              <strong>Email preferences:</strong>
            </p>
            <EmailSettingsTable />
          </>
        )}
      </Container>
    </>
  );
};

export default ProfilePage;
