import React from "react";

import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";

import EmailSettingsTable from "../EmailSettingsTable";

const EmailSettings: React.FC = () => {
  return (
    <>
      <TopMenuBar />
      <Container maxWidth="xl" sx={{ mt: 4, height: "100vh" }}>
        <h1>ChangePilot Email Preferences</h1>
        <p>
          As a ChangePilot user you receive an email each month updating you
          with Microsoft's new and updated change items across the M365 suite.
          On average, there are <strong>125</strong> M365 Message Center items and <strong>92</strong> M365 Roadmap
          items every month.
        </p>
        <p>
          With ChangePilot, you have the ability to only receive updates about
          the products and services you are interested in. You can also choose
          to only receive the high impacting items for either administrators or
          user adoption.
        </p>

        <EmailSettingsTable />
      </Container>
    </>
  );
};

export default EmailSettings;
