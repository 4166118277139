import React from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";
import useMyStatus from "../../services/useMyStatus";
import { useAuth0 } from "@auth0/auth0-react";
import NotLoggedInPage from "./NotLoggedInPage";
import FrontPageMessageCenterReport from "../FrontPageMessageCenterReport";
import { Helmet } from "react-helmet";
import Alert from "@mui/material/Alert";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

const DefaultPage: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  let metaTitle = "ChangePilot Portal";
  let metaDescription = `Microsoft 365 Message Center items - Stay on top of change with ChangePilot`;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };
  const { data } = useMyStatus();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {metaTitle && <meta property="og:title" content={metaTitle} />}
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        <meta property="og:type" content="website"></meta>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        {getUrl() && <meta property="og:url" content={getUrl()} />}
        <meta property="og:image" content={fallBackLogo} />
        <link rel="canonical" href={getUrl()} />
      </Helmet>
      <TopMenuBar />
      {isAuthenticated ? (
        <>
          {data && data.membershipType === "Free Membership" ? (
            <>
              <Box
                display="flex"
                bgcolor="#CD5E78"
                alignItems="center"
                justifyContent="center"
              >
                <Alert
                  variant="filled"
                  sx={{
                    backgroundColor: "#CD5E78",
                    display: "flex",
                    alignItems: "center",
                  }}
                  icon={<StarIcon fontSize="inherit" />}
                >
                  Your ChangePilot license has expired.
                  <a
                    href="https://changepilot.cloud/pricing"
                    className="bannerLink"
                  >
                    Click here
                  </a>{" "}
                  to find out more, or{" "}
                  <a
                    href="https://changepilot.cloud/contact-us"
                    className="bannerLink"
                  >
                    here
                  </a>{" "}
                  to contact one of the team.
                </Alert>
              </Box>
              <NotLoggedInPage />
            </>
          ) : (
            <>
              <Container maxWidth="xl" sx={{ mt: 4 }}>
                <FrontPageMessageCenterReport />
              </Container>
            </>
          )}
        </>
      ) : (
        <>
          <NotLoggedInPage />
        </>
      )}
    </>
  );
};

export default DefaultPage;
