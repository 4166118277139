import React from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";
import useMyStatus from "../../services/useMyStatus";
import { Helmet } from "react-helmet";
import useGetPowerBIUsageToken from "../../services/useGetPowerBIUsageToken";
import PowerBiChangePilotReport from "../powerBIEmbed";

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

interface props {
  StdReportId: string;
  RlsReportId: string;
  StdPageName: string;
  RlsPageName: string;
  reportTitle: string;
  reportIntroText: string;
}

const PowerBIReportContainerPage: React.FC<props> = (props) => {
  const token = useGetPowerBIUsageToken();
  const { data } = useMyStatus();

  let metaTitle = props.reportTitle + " - ChangePilot";
  let metaDescription =
    props.reportTitle + ` - Stay on top of change with ChangePilot`;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {metaTitle && <meta property="og:title" content={metaTitle} />}
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        <meta property="og:type" content="website"></meta>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        {getUrl() && <meta property="og:url" content={getUrl()} />}
        <meta property="og:image" content={fallBackLogo} />
        <link rel="canonical" href={getUrl()} />
      </Helmet>

      <TopMenuBar />

      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <h1>{props.reportTitle}</h1>
        <p>{props.reportIntroText}</p>

        {data && data.isTenantIdStored ? (
          <PowerBiChangePilotReport
            reportId={props.RlsReportId}
            token={token.data || ""}
            pageName={props.RlsPageName}
          />
        ) : (
          <></>
        )}

        {data && !data.isTenantIdStored ? (
          <PowerBiChangePilotReport
            reportId={props.StdReportId}
            token={token.data || ""}
            pageName={props.StdPageName}
          />
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default PowerBIReportContainerPage;
