import axios from "axios";
import { MessageCenterItem } from "../Models/MessageCenterItem";
import { MessageCenterEvent } from "../Models/MessageCenterEvent";
import { MessageCenterTableItem } from "../Models/MessageCenterTableItem";

const baseURL = "https://empoweringcloudapi.azurewebsites.net";
//const baseURL = "https://localhost:7172";

const getMessageCenterItem = async (
  messageCenterId: string
): Promise<MessageCenterItem> => {
  try {
    const response = await axios.get(
      `${baseURL}/MessageCenterInfo/${messageCenterId}`
    );
    const data = response.data;

    // Convert the JSON string to an object
    const responseObject: MessageCenterItem = data;

    return responseObject;
  } catch (error) {
    // Handle the error here

    console.log("Error calling the getMessageCenterItem API: ", error);
    throw error;
  }
};

const getMessageCenterEvents = async (
  messageCenterId: string
): Promise<MessageCenterEvent[]> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenterevent/${messageCenterId}`
    );
    const data = response.data;

    // Convert the JSON string to an object
    const responseObject: MessageCenterEvent[] = data;

    return responseObject;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the getMessageCenterEvents API: ", error);
    throw new Error("Failed to call the getMessageCenterEvents API");
  }
};

const getAllMessageCenterItems = async (): Promise<
  MessageCenterTableItem[]
> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenter/messagecenteritems`
    );
    const data = response.data;

    return data;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the API: ", error);
    throw new Error("Failed to call the API");
  }
};

const getMessageCenterItemsByProduct = async (
  product: string
): Promise<MessageCenterTableItem[]> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenter/messagecenteritemsbyservice?serviceName=${product}`
    );
    const data = response.data;

    return data;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the API: ", error);
    throw new Error("Failed to call the API");
  }
};

const getMessageCenterItemsByCategory = async (
  category: string
): Promise<MessageCenterTableItem[]> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenter/messagecenteritemsbycategory?category=${category}`
    );
    const data = response.data;

    return data;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the API: ", error);
    throw new Error("Failed to call the API");
  }
};

const getMessageCenterRecentItems = async (): Promise<
  MessageCenterTableItem[]
> => {
  try {
    const response = await axios.get(
      `${baseURL}/messagecenter/messagecenteritemsrecent`
    );
    const data = response.data;

    return data;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the API: ", error);
    throw new Error("Failed to call the API");
  }
};

const getAllProducts = async (): Promise<string[]> => {
  try {
    const response = await axios.get(`${baseURL}/messagecenter/products`);
    const data = response.data;

    return data;
  } catch (error) {
    // Handle the error here
    console.log("Error calling the API: ", error);
    throw new Error("Failed to call the API");
  }
};

const apiService = {
  getMessageCenterItem,
  getAllMessageCenterItems,
  getMessageCenterEvents,
  getMessageCenterItemsByProduct,
  getAllProducts,
  getMessageCenterItemsByCategory,
  getMessageCenterRecentItems,
};
export default apiService;
