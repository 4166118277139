import React, { useEffect, useState } from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import callApi from "../../services/apiService";
import { MessageCenterTableItem } from "../../Models/MessageCenterTableItem";

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

const AllItemsPage: React.FC = () => {
  let metaTitle = `All Items - ChangePilot `;
  let metaDescription = `All Microsoft 365 Message Center items`;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };
  const [messages, setMessages] = useState<MessageCenterTableItem[]>([]);
  const [prodcts, setProducts] = useState<string[]>([]);

  useEffect(() => {
    const fetchSiteList = async () => {
      try {
        const messages: MessageCenterTableItem[] =
          await callApi.getAllMessageCenterItems();
        const products: string[] = await callApi.getAllProducts();

        setMessages(messages);
        setProducts(products);
      } catch (error) {
        console.error("Failed to fetch all list:", error);
      }
    };

    fetchSiteList();
  }, []);

  return (
    <>
      <TopMenuBar />
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {metaTitle && <meta property="og:title" content={metaTitle} />}
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        <meta property="og:type" content="website"></meta>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        {getUrl() && <meta property="og:url" content={getUrl()} />}
        <meta property="og:image" content={fallBackLogo} />
        <link rel="canonical" href={getUrl()} />
      </Helmet>
      <div>
        <Container>
          <Box component="section" sx={{ p: 2 }}>
            <h1>All Items</h1>
            <h2>Products</h2>
            <ul>
              {prodcts.map((product) => (
                <li key={product}>
                  <a
                    href={`${window.location.origin}/${product
                      .toLowerCase()
                      .replace(/ /g, "-")
                      .replace("&", "and")}`}
                  >
                    {product}
                  </a>
                </li>
              ))}
            </ul>
            <h2>Message Center Items</h2>
            <ul>
              {messages.map((message) => (
                <li key={message.id}>
                  <a href={`${window.location.origin}/${message.id}`}>
                    {message.id} - {message.title}
                  </a>
                </li>
              ))}
            </ul>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default AllItemsPage;
