import React from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";
import useGetVideos from "../../services/useGetVideos";
import { VideoModel } from "../../Models/VideoModel";
import { WistiaPlayer } from "@wistia/wistia-player-react";
import Grid from "@mui/material/Grid2";

const ChangeCallsPage: React.FC = () => {
  const data = useGetVideos();
  return (
    <>
      <TopMenuBar />

      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <h1>ChangePilot Change Calls</h1>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 2, sm: 4, md: 6 }}
        >
          {data.data?.map((video: VideoModel) => {
            return (
              <Grid key={video.id} size={{ xs: 2, sm: 3, md: 3 }}>
                <h2>{video.title}</h2>
                <WistiaPlayer mediaId={video.embedCode} autoplay={false} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export default ChangeCallsPage;
