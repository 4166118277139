import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import useMyClaims from "../services/useMyClaims";
import useMyStatus from "../services/useMyStatus";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const userclaims = useMyClaims();
  const { data } = useMyStatus();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return (
      <>
        {user && (
          <>
            <div>
              <span style={{ fontSize: "12px" }}>
                {userclaims?.data
                  ? userclaims.data["http://empowering.cloud/displayName"]
                  : ""}
              </span>
              <br />
              <span style={{ fontSize: "12px" }}>
                {data?.organisationName && data?.organisationName.length > 0 ? (
                  data?.organisationName + " ✅"
                ) : (
                  <>
                    {userclaims?.data
                      ? userclaims.data["http://empowering.cloud/company"]
                      : ""}
                  </>
                )}
              </span>
            </div>
            {/* <Typography
              gutterBottom
              sx={{ color: "text.secondary", fontSize: 12 }}
            >
              License Type: {data?.membershipType}{" "}
              {data?.daysLeftText !== ""
                ? "(" + data?.daysLeftText + ")"
                : null}
            </Typography> */}
          </>
        )}
      </>
    );
  }

  return <></>;
};

export default Profile;
