import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import useMyStatus from "../services/useMyStatus";
import TrialExpiredPage from "./pages/TrialExpiredPage";

interface AuthenticationGuardProps {
  component: ComponentType<any>;
  requiresMembership: boolean;
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
  requiresMembership,
}) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <div className="page-layout">page loading</div>,
  });
  const { data } = useMyStatus();

  if (data && requiresMembership && data.membershipType === "Free Membership") {
    return <TrialExpiredPage />;
  }

  return <Component />;
};
