import React from "react";
import Container from "@mui/material/Container";
import {
  useMyEmailSettings,
  useUpdateEmailSettings,
} from "../services/useGetSetMyEmailSettings";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { themeBalham } from "ag-grid-community";
import { GridOptions, ICellRendererParams } from "ag-grid-community";

import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const EmailSettingsTable: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const queryClient = new QueryClient();

  if (isAuthenticated) {
    return (
      <>
        <QueryClientProvider client={queryClient}>
          <EmailSettings />
        </QueryClientProvider>
      </>
    );
  } else {
    return <></>;
  }

  function EmailSettings() {
    const queryClient = useQueryClient();
    const [isEditing, setIsEditing] = useState(false);
    const [emailCadence, setEmailCadence] = useState("W");
    const [errorSavingOpen, setErrorSavingOpen] = useState(false);
    const [successSavingOpen, setSuccessSavingOpen] = useState(false);
    const query = useQuery({
      queryKey: ["emailSettings"],
      queryFn: useMyEmailSettings(),
    });
    const mutation = useMutation({
      mutationFn: useUpdateEmailSettings(),
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: ["emailSettings"] });
      },
    });

    const handleEmailFrequencyChange = (event: SelectChangeEvent<string>) => {
      setEmailCadence(event.target.value);
    };

    const Save = async () => {
      if (query.data != null) {
        const model = {
          cadence: emailCadence,
          categories: query.data.categories,
        };
        const result = await mutation.mutateAsync(model);
        return result;
      }
    };

    const gridTheme = themeBalham.withParams({
      headerHeight: 30,
      headerTextColor: "black",
      rangeSelectionBorderColor: "rgb(60, 188, 0, 0.0)",
    });

    function emailCadenceValue(value: string) {
      if (value === "M") {
        return "Monthly";
      } else if (value === "W") {
        return "Weekly";
      } else {
        return "Unknown";
      }
    }

    const selectedRenderer: React.FC<ICellRendererParams> = (props) => {
      if (props.value === true) {
        return <div style={{ textAlign: "center" }}>🟢</div>;
      } else {
        return <span></span>;
      }
    };

    const handleErrorClose = (
      event?: React.SyntheticEvent | Event,
      reason?: SnackbarCloseReason
    ) => {
      if (reason === "clickaway") {
        return;
      }

      setErrorSavingOpen(false);
    };

    const handleSuccessClose = (
      event?: React.SyntheticEvent | Event,
      reason?: SnackbarCloseReason
    ) => {
      if (reason === "clickaway") {
        return;
      }

      setSuccessSavingOpen(false);
    };

    const columnDefs: GridOptions["columnDefs"] = [
      {
        headerName: "Service Category",
        field: "category",
        width: 200,
      },
      {
        headerName: "Include Roadmap",
        field: "includeRoadmap",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        flex: 1,
        editable: isEditing,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
      {
        headerName: "Admin Impact High",
        field: "adminHigh",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        editable: isEditing,
        flex: 1,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
      {
        headerName: "Admin Impact Medium",
        field: "adminMedium",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        editable: isEditing,
        flex: 1,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
      {
        headerName: "Admin Impact Low",
        field: "adminLow",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        editable: isEditing,
        flex: 1,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
      {
        headerName: "User Impact High",
        field: "userHigh",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        editable: isEditing,
        flex: 1,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
      {
        headerName: "User Impact Medium",
        field: "userMedium",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        editable: isEditing,
        flex: 1,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
      {
        headerName: "User Impact Low",
        field: "userLow",
        wrapHeaderText: true,
        cellClass: "cellCenter",
        headerClass: "headerCenter",
        editable: isEditing,
        flex: 1,
        cellRenderer: isEditing ? "agCheckboxCellRenderer" : selectedRenderer,
      },
    ];

    return (
      <>
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {!isEditing && (
            <p>You are receiving emails: {emailCadenceValue(emailCadence)}</p>
          )}

          {isEditing && (
            <div>
              <FormControl sx={{ mt: 2, mb: 2, minWidth: 120 }}>
                <InputLabel id="edit-email-frequency">
                  Receive Emails
                </InputLabel>
                <Select
                  labelId="edit-email-frequency"
                  id="edit-email-frequency"
                  value={emailCadence}
                  label="Email Frequency"
                  onChange={handleEmailFrequencyChange}
                >
                  <MenuItem value={"W"}>Weekly</MenuItem>
                  <MenuItem value={"M"}>Monthly</MenuItem>
                </Select>
              </FormControl>
            </div>
          )}
          <div
            className="ag-theme-balham"
            style={{
              height: "100%",
              width: "100%",
              minHeight: "100%",
              marginTop: "10px",
              overflow: "hidden",
              position: "relative",
              marginBottom: "20px",
            }}
          >
            {query.data != null && (
              <AgGridReact
                columnDefs={columnDefs}
                rowData={query.data.categories}
                theme={gridTheme}
                readOnlyEdit={!isEditing}
                suppressRowHoverHighlight={true}
                suppressRowDrag={true}
                columnHoverHighlight={false}
                domLayout="autoHeight"
              />
            )}
          </div>

          <Button
            variant="contained"
            onClick={async () => {
              if (!isEditing) {
                setIsEditing(!isEditing);
              } else {
                var result = await Save();
                if (result) {
                  console.log("Settings Saved");
                  setSuccessSavingOpen(true);
                  setIsEditing(!isEditing);
                } else {
                  console.log("Error saving settings");
                  setErrorSavingOpen(true);
                }
              }
            }}
          >
            {isEditing ? "Save" : "Edit"} Preferences
          </Button>
        </Container>
        <Snackbar
          open={errorSavingOpen}
          autoHideDuration={3000}
          onClose={handleErrorClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleErrorClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            There was a problem saving your email settings. Please try again.
          </Alert>
        </Snackbar>

        <Snackbar
          open={successSavingOpen}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSuccessClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Your email settings have been updated.
          </Alert>
        </Snackbar>
      </>
    );
  }
};
export default EmailSettingsTable;
