import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { MyDetailsModel } from "../Models/MyDetailsModel";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const baseURL = "https://empoweringcloudapi.azurewebsites.net";
//const baseURL = "https://localhost:7172";

export default function useMyStatus(): UseQueryResult<MyDetailsModel, unknown> {
  const { user, getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ["mystatus"],
    queryFn: async () => {
      const accessToken = user != null ? await getAccessTokenSilently() : null;

      const { data } = await axios.get(`${baseURL}/me/GetMyDetails`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      //console.log(data);
      return data;
    },
    enabled: !!user,
  });
}
