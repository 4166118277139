import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export default function useMyClaims(): UseQueryResult<IdToken, unknown> {
  const { user, getIdTokenClaims } = useAuth0();

  return useQuery({
    queryKey: ["myclaims"],
    queryFn: async () => {
      const claims = user != null ? await getIdTokenClaims() : null;

      //console.log(claims);

      return claims;
    },
    enabled: !!user,
  });
}
