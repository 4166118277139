import React from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";

import { Button } from "@mui/material";
import useMyStatus from "../../services/useMyStatus";

const TenantSettings: React.FC = () => {
  const { data } = useMyStatus();
  const adminConsentUrl =
    "https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=ae2d453e-3db2-410a-966c-b229e6425534&scope=https://graph.microsoft.com/.default&redirect_uri=" +
    window.location.origin +
    "/adminconsent";

  return (
    <>
      <TopMenuBar />
      <Container maxWidth="xl" sx={{ mt: 4, height: "100vh" }}>
        <h1>Tenant Connection</h1>
        <p>
          Tenant connection status:{" "}
          <strong>
            {data?.isTenantIdStored ? "Tenant Specific Feed" : "Standard Feed"}
          </strong>
        </p>

        {data && !data?.isTenantIdStored ? (
          <>
            <p>
              <strong>
                Get the most from ChangePilot by connecting your Microsoft 365
                tenant(s).
              </strong>{" "}
              By giving ChangePilot read only access to your Message Center, you
              will see all messages specifically for your environment. Once
              connected, your ChangePilot Email will also be specifically for
              your tenants' messages.
            </p>

            <p>
              Microsoft target messages your tenant receivea based on licensing
              and usage and your configuration.
            </p>

            <p>
              If you don’t connect a tenant, you will see the{" "}
              <strong>standard feed</strong> of Microsoft 365 Message Centre
              messages for typical tenants. This means you may see messages
              about Microsoft 365 products and services that you do not have
              licenses for or use, and will not see important messages sent
              specifically to your tenant that require action based on your
              tenant's specific configuration.
            </p>
            <p>
              For more information about the read-only permissions required,
              see:{" "}
              <a
                href="https://changepilot.cloud/knowledge-base/what-permissions-does-changepilot-require-from-our-tenant-"
                target="_blank"
                rel="noreferrer noopener"
              >
                What permissions does ChangePilot require from our tenant?
              </a>
            </p>
            <p>Tenant connection requires the following permissions:</p>
            <ul>
              <li>Read service health</li>
              <li>Read service messages</li>
              <li>Sign in and read user profile</li>
            </ul>
            <Button
              href={adminConsentUrl}
              target="_blank"
              variant="contained"
              color="primary"
            >
              View permissions and connect to M365 tenant
            </Button>
            <p>
              <i>
                Takes you Microsoft Online. M365 Administrator account required.
              </i>
            </p>
          </>
        ) : (
          <>
            <p>You have successfully connected to your tenant. </p>
            <p>
              Data Gathering Status:{" "}
              <strong>{data?.dataGatheringStatus}</strong>
            </p>
            <p>
              <strong>Next Steps:</strong>
              <ul>
                <li>
                  It can take up to 8 hours for data to be collected from your
                  tenant the first time.
                </li>
                <li>
                  Once collected, your ChangePilot home page will automatically
                  show data from your tenant.
                </li>
              </ul>
            </p>
          </>
        )}
      </Container>
    </>
  );
};

export default TenantSettings;
