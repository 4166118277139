import React, { useState, useEffect, useRef } from "react";
import TopMenuBar from "../TopMenuBar";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";
import { useSetAdminConsent } from "../../services/useSetAdminConsent";

import { AdminConsentModel } from "../../Models/AdminConsentModel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AdminConsentLanding: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const query = useQuery();
  const adminConsent = query.get("admin_consent");
  const tenant = query.get("tenant");
  const [tenantStored, setTenantStored] = useState<Boolean>(false);
  const [issueStoring, setIssueStoring] = useState<Boolean>(false);
  const tenantSet = useRef(false);

  const setAdminConsent = useSetAdminConsent();

  useEffect(() => {
    const model: AdminConsentModel = {
      tenantId: tenant ? tenant : "",
    };

    const setTenantInfo = async () => {
      if (isLoading) {
        console.log("Auth0 not ready");
        return;
      }

      if (tenantSet.current) {
        return;
      }
      tenantSet.current = true;

      const consentResult = await setAdminConsent(model);

      if (consentResult === undefined) {
        return;
      }

      if (consentResult) {
        setTenantStored(true);
      } else {
        setIssueStoring(true);
      }
    };
    setTenantInfo();
  }, [setAdminConsent, tenant, isLoading]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return (
      <>
        <TopMenuBar />
        <Container maxWidth="xl">
          {adminConsent === "True" && tenantStored && (
            <>
              <h1>Consent Complete</h1>
              <p>
                You have successfully consented to the ChangePilot application.{" "}
              </p>
              <Button href="/settings/tenant" variant="contained">
                Continue
              </Button>
            </>
          )}

          {adminConsent === "True" && !tenantStored && !issueStoring && (
            <>
              <h1>Processing Consent...</h1>
              <p>Please wait whilst we process your consent.</p>
            </>
          )}

          {(adminConsent === "False" || adminConsent == null) && (
            <>
              <h1>Consent Failed</h1>
              <p>
                You have not consented to the ChangePilot application, or
                something went wrong. Please try again.
              </p>
            </>
          )}

          {issueStoring && (
            <>
              <h1>Consent Failed</h1>
              <p>
                Something went wrong storing the tenant information. Please try
                again.
              </p>
            </>
          )}
        </Container>
      </>
    );
  } else {
    return (
      <>
        <p>Something went wrong.</p>
      </>
    );
  }
};

export default AdminConsentLanding;
