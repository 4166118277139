import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { MyEmailSettingsModel } from "../Models/MyEmailSettings";

const baseURL = "https://empoweringcloudapi.azurewebsites.net";
//const baseURL = "https://localhost:7172";

export function useMyEmailSettings() {
  const { user, getAccessTokenSilently } = useAuth0();
  const getEmailSettings = async (): Promise<MyEmailSettingsModel> => {
    try {
      const accessToken = user != null ? await getAccessTokenSilently() : null;

      const { data } = await axios.get(`${baseURL}/me/GetMyEmailSettings`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(data);
      const responseObject: MyEmailSettingsModel = data;
      return responseObject;
    } catch (error) {
      // Handle the error here

      console.log("Error calling the getMyEmailSettings API: ", error);
      throw error;
    }
  };

  return getEmailSettings;
}

export function useUpdateEmailSettings() {
  const { user, getAccessTokenSilently } = useAuth0();

  const updateEmailSettings = async (model: MyEmailSettingsModel) => {
    console.log("useUpdateEmailSettings called with model: ", model);

    try {
      const accessToken = user != null ? await getAccessTokenSilently() : null;

      const response = await axios.post(
        `${baseURL}/me/UpdateMyEmailSettings`,
        model,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Returning true");
        return true;
      }

      console.log("Returning false");
      return false;
    } catch (error) {
      // Handle the error here
      console.log(
        "Error calling the POST /me/UpdateMyEmailSettings API: ",
        error
      );
      console.log("Returning false");
      return false;
    }
  };

  return updateEmailSettings;
}
