import React, { useEffect, useState } from "react";
import callApi from "./services/apiService";

interface Site {
  id: string;
  title: string;
  lastUpdated: Date;
}

const SitemapRouteHandler: React.FC = () => {
  const [xmlContent, setXmlContent] = useState("");

  useEffect(() => {
    const fetchSiteList = async () => {
      try {
        const sites: Site[] = await callApi.getAllMessageCenterItems();
        const products: string[] = await callApi.getAllProducts();

        const xml = generateSitemapXML(sites, products);
        setXmlContent(xml);
      } catch (error) {
        console.error("Failed to fetch site list:", error);
      }
    };

    fetchSiteList();
  }, []);

  const generateSitemapXML = (sites: Site[], products: string[]): string => {
    const productUrls = products
      .map((item) => {
        return `
        <url>
          <loc>${window.location.origin}/${item
          .toLowerCase()
          .replace(/ /g, "-")
          .replace("&", "and")}
          </loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>`;
      })
      .join("");
    const urls = sites
      .map((site) => {
        return `
        <url>
          <loc>${window.location.origin}/${site.id}</loc>
          <lastmod>${new Date(site.lastUpdated).toISOString()}</lastmod>
        </url>`;
      })
      .join("");

    return `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
     <url>
          <loc>${window.location.origin}/</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>
    <url>
          <loc>${window.location.origin}/all</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>
         <url>
          <loc>${window.location.origin}/stay-informed</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>
         <url>
          <loc>${window.location.origin}/prevent-or-fix-issues</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>
         <url>
          <loc>${window.location.origin}/plan-for-change</loc>
          <lastmod>${new Date().toISOString()}</lastmod>
        </url>
      ${productUrls}  
      ${urls}
    </urlset>`;
  };

  return <pre>{xmlContent}</pre>;
};

export default SitemapRouteHandler;
