import React from "react";
import TopMenuBar from "../TopMenuBar";
import NotFound from "../NotFound";

const NotFoundPage: React.FC = () => {
  return (
    <>
      <TopMenuBar />
      <NotFound />
    </>
  );
};

export default NotFoundPage;
