import React from "react";
import TopMenuBar from "../TopMenuBar";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";

const TrialExpiredPage: React.FC = () => {
  return (
    <>
      <TopMenuBar />
      <Box
        display="flex"
        bgcolor="#CD5E78"
        alignItems="center"
        justifyContent="center"
      >
        <Alert
          variant="filled"
          sx={{
            backgroundColor: "#CD5E78",
            display: "flex",
            alignItems: "center",
          }}
          icon={<StarIcon fontSize="inherit" />}
        >

          Your ChangePilot license has expired.
          <a href="https://changepilot.cloud/pricing" className="bannerLink">
            Click here
          </a>{" "}
          to find out more, or{" "}
          <a href="https://changepilot.cloud/contact-us" className="bannerLink">
            here
          </a>{" "}
          to contact one of the team.

        </Alert>
      </Box>
      <Container maxWidth="xl" sx={{ mt: 4, textAlign: "center" }}>
        <h1>
          It looks like you don't have the nessecary
          <br /> ChangePilot license to access this page.
        </h1>
        <p>
          To find out more about our plans, please visit the{" "}
          <a href="https://changepilot.cloud/pricing">ChangePilot website</a>.
        </p>
        <p>
          To speak to one of the team, or if you believe this is an error,{" "}
          <a href="https://changepilot.cloud/contact-us">contact us</a> here or
          use our chat box.
        </p>
      </Container>
    </>
  );
};

export default TrialExpiredPage;
