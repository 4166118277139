import React from "react";

import useMyStatus from "../services/useMyStatus";
import PowerBiChangePilotReport from "./powerBIEmbed";
import useGetPowerBIUsageToken from "../services/useGetPowerBIUsageToken";

const FrontPageMessageCenterReport: React.FC = () => {
  const { data } = useMyStatus();
  const publicFeedReportId = "aa75c76e-d56d-427f-8b6d-54741217b28b";
  const stdFeedReportId = "328fad1f-ef7d-4050-8e7c-2e0c2928b244";
  const token = useGetPowerBIUsageToken();
  return (
    <>
      {data && data.isTenantIdStored ? (
        <PowerBiChangePilotReport
          reportId={stdFeedReportId}
          token={token.data || ""}
          pageName="c24251d8bcf19a9bf7bb"
        />
      ) : (
        <></>
      )}

      {data && !data.isTenantIdStored ? (
        <PowerBiChangePilotReport
          reportId={publicFeedReportId}
          token={token.data || ""}
          pageName="5830e0cdd7851f1f87ac"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default FrontPageMessageCenterReport;
