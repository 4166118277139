import React, { useRef } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";

import "../styles/pbiembed.css";

interface props {
  reportId: string;
  token: string;
  pageName?: string;
}

const PowerBiChangePilotReport = (props: props) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const reportRef = useRef<any>(null);

  const fullScreen = () => {
    if (reportRef.current) {
      reportRef.current.fullscreen();
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return (
      <>
        {props.token && (
          <>
            <PowerBIEmbed
              embedConfig={{
                type: "report", // Supported types: report, dashboard, tile, visual and qna
                id: props.reportId,
                embedUrl: undefined,
                pageName: props.pageName ? props.pageName : undefined,

                accessToken: props.token, // Keep as empty string, null or undefined
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                    pageNavigation: {
                      visible: false,
                    },
                  },
                  background: models.BackgroundType.Default,
                },
              }}
              cssClassName="pbi-embed"
              getEmbeddedComponent={(embedObject) => {
                reportRef.current = embedObject;
              }}
            />
            <Box maxWidth="100%" textAlign={"right"} marginTop={"5px"}>
              <Button variant="contained" onClick={fullScreen}>
                Full Screen
              </Button>
            </Box>
          </>
        )}
      </>
    );
  }

  return <></>;
};

export default PowerBiChangePilotReport;
