import React, { useEffect, useState, useRef } from "react";
import callApi from "../../services/apiService";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import { MessageCenterTableItem } from "../../Models/MessageCenterTableItem";

import { Helmet } from "react-helmet";

import MessageCenterTable from "../MessageCenterTable";

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

const NotLoggedInPage: React.FC = () => {
  const [items, setItems] = useState<MessageCenterTableItem[]>();
  const { loginWithRedirect } = useAuth0();
  const effectRan = useRef(false);
  const handleLogin = () => {
    loginWithRedirect();
  };
  const handleSignup = () => {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
    });
  };

  let metaTitle = "ChangePilot Portal";
  let metaDescription = `Microsoft 365 Message Center items - Stay on top of change with ChangePilot`;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };

  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    const fetchRecentItems = async () => {
      const items: MessageCenterTableItem[] =
        await callApi.getMessageCenterRecentItems();
      setItems(items);
      //console.log(items);
    };

    fetchRecentItems();
  }, []);

  return (
    <>
      <Container maxWidth="lg" style={{ marginTop: "4vh" }}>
        <Helmet>
          <title>{metaTitle}</title>
          {metaDescription && (
            <meta name="description" content={metaDescription} />
          )}
          {metaTitle && <meta property="og:title" content={metaTitle} />}
          {metaDescription && (
            <meta property="og:description" content={metaDescription} />
          )}
          <meta property="og:type" content="website"></meta>
          <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
          {getUrl() && <meta property="og:url" content={getUrl()} />}
          <meta property="og:image" content={fallBackLogo} />
          <link rel="canonical" href={getUrl()} />
        </Helmet>

        <Stack alignItems="center">
          <h1 style={{ color: "#2E3838" }}>
            Welcome to the ChangePilot Portal
          </h1>
          <p>
            Home for your tenant's M365 change information, powered by
            ChangePilot.
          </p>

          <Stack direction={"row"} spacing={2} marginTop={2} marginBottom={5}>
            <Button
              href="https://changepilot.cloud"
              target="_blank"
              rel="noopener noreferrer"
              variant={"contained"}
              color={"secondary"}
              style={{ minWidth: 200 }}
            >
              More info
            </Button>
            <Button
              onClick={handleSignup}
              variant={"contained"}
              style={{ backgroundColor: "#366D5A", minWidth: 200 }}
            >
              Get started with a 6-week trial
            </Button>
          </Stack>
          <p>
            Efficiently and confidently manage Microsoft 365 change with
            ChangePilot:
          </p>

          <Grid
            container
            spacing={3}
            marginTop={2}
            marginBottom={2}
            textAlign={"center"}
          >
            <Grid size={2}>
              <strong>Expert Reviews</strong>
              <p>
                Every M365 change item is reviewed, summarized and rated for
                impact by experts.
              </p>
            </Grid>
            <Grid size={2}>
              <strong>Monthly Change Call</strong>
              <p>
                Join our Microsoft MVP-led change call explaining each change in
                detail. Access recorded sessions on demand with convenient
                chapter marks.
              </p>
            </Grid>
            <Grid size={2}>
              <strong>Email Summaries</strong>
              <p>
                Customised ChangePilot email summaries sent to IT admin/service
                owners, filtered as you see fit by workloads and impact.
              </p>
            </Grid>
            <Grid size={2}>
              <strong>ChangePilot Portal</strong>
              <p>
                Interactive Power BI reports of all your tenant's messages with
                summaries and explainer video links.
              </p>
            </Grid>

            <Grid size={2}>
              <strong>Tenant Connection</strong>
              <p>
                Simple 1-click read-only access to tenant Message Center API to
                receive updates specifically for your M365 environment.
              </p>
            </Grid>
            <Grid size={2}>
              <strong>ChangePilot Pro</strong>
              <p>
                adds a full best practice management process leveraging Teams,
                ChangePilot Power App, Power BI and Power Automate to push items
                to your ITSM platform.
              </p>
            </Grid>
          </Grid>
          <p>Already a ChangePilot member?</p>
          <Button
            onClick={handleLogin}
            variant={"contained"}
            style={{ backgroundColor: "#00CC99" }}
          >
            Sign In
          </Button>
        </Stack>

        <Container style={{ marginTop: "10" }}>
          <h3>Latest Microsoft 365 Message Center Items</h3>
          <p>
            Our dedicated team of Microsoft MVPs reviews, summarizes, and
            assigns impact scores to every change from the M365 Message Center.
            Below, you can find the 30 most recent items.
          </p>
          <p>
            Note each Microsoft 365 tenant gets a unique feed of messages based
            on type, licences, configuration, usage and location.
          </p>
          <p>
            To access all M365 Message Center messages and Roadmap items, sign
            in and start a 60 day trial{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://changepilot.cloud"
            >
              here
            </a>
          </p>
          <MessageCenterTable items={items || []}></MessageCenterTable>
        </Container>
      </Container>
    </>
  );
};

export default NotLoggedInPage;
