import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "../images/ChangePilotWhite300px.png";
import Container from "@mui/material/Container";
import { useAuth0 } from "@auth0/auth0-react";
import IconButton from "@mui/material/IconButton";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Profile from "./userProfileInfo";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import StarIcon from "@mui/icons-material/Star";
import useMyStatus from "../services/useMyStatus";
import { Link } from "react-router-dom";
//import FirstRun from "./FirstRun";
import { useState } from "react";
import { SnackbarCloseReason } from "@mui/material/Snackbar";

const TopMenuBar: React.FC = () => {
  const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElRoadmap, setAnchorElRoadmap] =
    React.useState<null | HTMLElement>(null);
  const msgCenterOpen = Boolean(anchorEl);
  const roadmapOpen = Boolean(anchorElRoadmap);
  const [tenantAlertOpen, setTenantAlertOpen] = useState(true);

  const handleMsgCenterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMsgCenterClose = () => {
    setAnchorEl(null);
  };

  const handleRoadmapClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRoadmap(event.currentTarget);
  };
  const handleRoadmapClose = () => {
    setAnchorElRoadmap(null);
  };

  const handleTenantAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setTenantAlertOpen(false);
  };

  const [anchorElMobile, setAnchorElMobile] =
    React.useState<null | HTMLElement>(null);
  const msgCenterOpenMobile = Boolean(anchorElMobile);

  const [anchorElMobileRoadmap, setAnchorElMobileRoadmap] =
    React.useState<null | HTMLElement>(null);
  const roadmapOpenMobile = Boolean(anchorElMobileRoadmap);

  const handleMsgCenterClickMobile = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElMobile(event.currentTarget);
  };
  const handleMsgCenterCloseMobile = () => {
    setAnchorElMobile(null);
  };

  const handleRoadmapClickMobile = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElMobileRoadmap(event.currentTarget);
  };
  const handleRoadmapCloseMobile = () => {
    setAnchorElMobileRoadmap(null);
  };

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { data } = useMyStatus();

  return (
    <>
      {/* <FirstRun /> */}
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link to="/">
              <Box
                component="img"
                width={"150px"}
                src={logo}
                alt="ChangePilot"
                sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
              />
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                <MenuItem key="message_center" href="/">
                  {/* <Typography sx={{ textAlign: "center" }}>
                    Message Center
                  </Typography> */}
                  <Button
                    id="msgcenter-buttonMobile"
                    aria-controls={msgCenterOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={msgCenterOpen ? "true" : undefined}
                    onClick={handleMsgCenterClickMobile}
                    className="btnMenuItem"
                  >
                    Message Center
                  </Button>
                  <Menu
                    id="msgcenter-menuMobile"
                    anchorEl={anchorEl}
                    open={msgCenterOpenMobile}
                    onClose={handleMsgCenterCloseMobile}
                    MenuListProps={{
                      "aria-labelledby": "msgcenter-buttonMobile",
                    }}
                  >
                    <MenuItem>
                      <Button
                        key="msgcenter_allitems"
                        href="/reports/messagecenter/all"
                        className="btnMenuItem"
                      >
                        All Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="msgcenter_action"
                        href="/reports/messagecenter/action"
                        className="btnMenuItem"
                      >
                        Actionable Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="msgcenter_new"
                        href="/reports/messagecenter/new"
                        className="btnMenuItem"
                      >
                        New Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="msgcenter_upcoming"
                        href="/reports/messagecenter/upcoming"
                        className="btnMenuItem"
                      >
                        Upcoming Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="msgcenter_highadmin"
                        href="/reports/messagecenter/highadmin"
                        className="btnMenuItem"
                      >
                        High Admin Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="msgcenter_highuser"
                        href="/reports/messagecenter/highuser"
                        className="btnMenuItem"
                      >
                        High User Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="msgcenter_preview"
                        href="/reports/messagecenter/preview"
                        className="btnMenuItem"
                      >
                        Items with Preview Dates
                      </Button>
                    </MenuItem>
                  </Menu>
                </MenuItem>
                <MenuItem key="roadmap" href="/">
                  <Button
                    id="roadmap-buttonMobile"
                    aria-controls={roadmapOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={roadmapOpen ? "true" : undefined}
                    onClick={handleRoadmapClickMobile}
                    className="btnMenuItem"
                  >
                    Roadmap
                  </Button>
                  <Menu
                    id="roadmap-menuMobile"
                    anchorEl={anchorElRoadmap}
                    open={roadmapOpenMobile}
                    onClose={handleRoadmapCloseMobile}
                    MenuListProps={{
                      "aria-labelledby": "roadmap-buttonMobile",
                    }}
                  >
                    <MenuItem>
                      <Button
                        key="roadmap_allitems"
                        href="/reports/roadmap/all"
                        className="btnMenuItem"
                      >
                        All Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="roadmap_action"
                        href="/reports/roadmap/new"
                        className="btnMenuItem"
                      >
                        New Items
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="roadmap_dates"
                        href="/reports/roadmap/dates"
                        className="btnMenuItem"
                      >
                        Date Changes
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="roadmap_rollingout"
                        href="/reports/roadmap/rollingout"
                        className="btnMenuItem"
                      >
                        Rolling Out Soon
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="roadmap_launched"
                        href="/reports/roadmap/launched"
                        className="btnMenuItem"
                      >
                        Recently Launched
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        key="roadmap_removed"
                        href="/reports/roadmap/removed"
                        className="btnMenuItem"
                      >
                        Removed Items
                      </Button>
                    </MenuItem>
                  </Menu>
                </MenuItem>
                <MenuItem key="change_calls" href="/changecalls">
                  <Typography sx={{ textAlign: "center" }}>
                    Change Calls
                  </Typography>
                </MenuItem>
                <MenuItem key="changepilot_email" href="/settings/email">
                  <Typography sx={{ textAlign: "center" }}>
                    ChangePilot Email
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Link to="/">
              <Box
                component="img"
                width={"150px"}
                src={logo}
                alt="ChangePilot"
                sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
              />
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {/* <Button
                key="message_center"
                href="/"
                sx={{
                  my: 2,
                  mx: 2,
                  color: "white",
                  minWidth: 0,
                  display: "block",
                  textTransform: "none",
                }}
              >
                Message Center
              </Button> */}
              <Button
                sx={{
                  my: 2,
                  mx: 2,
                  color: "white",
                  minWidth: 0,
                  display: "block",
                  textTransform: "none",
                }}
                id="msgcenter-button"
                aria-controls={msgCenterOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={msgCenterOpen ? "true" : undefined}
                onClick={handleMsgCenterClick}
                className="btnMenuItem"
              >
                Message Center
              </Button>
              <Menu
                id="msgcenter-menu"
                anchorEl={anchorEl}
                open={msgCenterOpen}
                onClose={handleMsgCenterClose}
                MenuListProps={{
                  "aria-labelledby": "msgcenter-button",
                }}
              >
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/all"
                    className="btnMenuItem"
                  >
                    All Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/action"
                    className="btnMenuItem"
                  >
                    Actionable Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/new"
                    className="btnMenuItem"
                  >
                    New Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/upcoming"
                    className="btnMenuItem"
                  >
                    Upcoming Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/highadmin"
                    className="btnMenuItem"
                  >
                    High Admin Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/highuser"
                    className="btnMenuItem"
                  >
                    High User Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="msgcenter_allitems"
                    href="/reports/messagecenter/preview"
                    className="btnMenuItem"
                  >
                    Items with Preview Dates
                  </Button>
                </MenuItem>
              </Menu>
              <Button
                key="roadmap"
                sx={{
                  my: 2,
                  mx: 2,
                  minWidth: 0,
                  color: "white",
                  display: "block",
                  textTransform: "none",
                }}
                id="roadmap-button"
                aria-controls={roadmapOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={roadmapOpen ? "true" : undefined}
                onClick={handleRoadmapClick}
                className="btnMenuItem"
              >
                Roadmap
              </Button>
              <Menu
                id="roadmap-menu"
                anchorEl={anchorElRoadmap}
                open={roadmapOpen}
                onClose={handleRoadmapClose}
                MenuListProps={{
                  "aria-labelledby": "roadmap-button",
                }}
              >
                <MenuItem>
                  <Button
                    key="roadmap_allitems"
                    href="/reports/roadmap/all"
                    className="btnMenuItem"
                  >
                    All Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="roadmap_action"
                    href="/reports/roadmap/new"
                    className="btnMenuItem"
                  >
                    New Items
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="roadmap_dates"
                    href="/reports/roadmap/dates"
                    className="btnMenuItem"
                  >
                    Date Changes
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="roadmap_rollingout"
                    href="/reports/roadmap/rollingout"
                    className="btnMenuItem"
                  >
                    Rolling Out Soon
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="roadmap_launched"
                    href="/reports/roadmap/launched"
                    className="btnMenuItem"
                  >
                    Recently Launched
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    key="roadmap_removed"
                    href="/reports/roadmap/removed"
                    className="btnMenuItem"
                  >
                    Removed Items
                  </Button>
                </MenuItem>
              </Menu>
              <Button
                key="change_calls"
                href="/changecalls"
                sx={{
                  my: 2,
                  mx: 2,
                  minWidth: 0,
                  color: "white",
                  display: "block",
                  textTransform: "none",
                }}
                className="btnMenuItem"
              >
                Change Calls
              </Button>
              <Button
                key="changepilot_email"
                href="/settings/email"
                sx={{
                  my: 2,
                  mx: 2,
                  color: "white",
                  minWidth: 0,
                  display: "block",
                  textTransform: "none",
                }}
                className="btnMenuItem"
              >
                ChangePilot Email
              </Button>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <PersonOutlinedIcon
                    fontSize="large"
                    sx={{ color: "#ffffff" }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {isAuthenticated && user && (
                  <>
                    <Link
                      to="/settings/email"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem>Email Settings</MenuItem>
                    </Link>
                    <Link
                      to="/settings/tenant"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem>Tenant Connection</MenuItem>
                    </Link>
                    <Link
                      to="/profile"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem>Profile</MenuItem>
                    </Link>
                    <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                  </>
                )}
                {!isAuthenticated && (
                  <MenuItem onClick={handleLogin}>Sign In</MenuItem>
                )}
              </Menu>
            </Box>
            <Profile />
          </Toolbar>
        </Container>

        {isAuthenticated &&
          data &&
          !data?.isTenantIdStored &&
          tenantAlertOpen && (
            <Box
              display="flex"
              bgcolor="#00CC99"
              alignItems="center"
              justifyContent="center"
            >
              <Alert
                variant="filled"
                onClose={handleTenantAlertClose}
                sx={{
                  backgroundColor: "#00CC99",
                  display: "flex",
                  alignItems: "center",
                }}
                icon={<StarIcon fontSize="inherit" />}
              >
                You are viewing the standard ChangePilot feed.{" "}
                <a href="/settings/tenant" className="bannerLink">
                  Click here
                </a>{" "}
                for more information and to connect your tenant for a feed
                specific to your M365 environment.
              </Alert>
            </Box>
          )}
      </AppBar>
    </>
  );
};

export default TopMenuBar;
