import React from "react";
import MessageCenterDetail from "./components/MessageCenterDetail";
import SitemapRouteHandler from "./SitemapRouteHandler";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Redirect from "./components/Redirect";
import AllItemsPage from "./components/pages/AllItemsPage";
import DefaultPage from "./components/pages/DefaultPage";
import ProductPage from "./components/pages/ProductPage";
import Footer from "./components/Footer";
import CategoryPage from "./components/CategoryPage";
import EmailSettings from "./components/pages/EmailSettingsPage";
import TenantSettings from "./components/pages/TenantSettingsPage";
import { AuthenticationGuard } from "./components/Authentication-Guard";
import { useAuth0 } from "@auth0/auth0-react";
import ChangeCallsPage from "./components/pages/ChangeCallsPage";
import ProfilePage from "./components/pages/ProfilePage";
import PowerBIReportContainerPage from "./components/pages/PowerBIReportContainerPage";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";

import "./App.css";
import AdminConsentLanding from "./components/pages/AdminConsentLanding";
import NotFoundPage from "./components/pages/NotFoundPage";

interface Params extends Record<string, string | undefined> {
  param: string;
}

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: { main: "#2E3838" },
    secondary: { main: "#C23665" },
    tertiary: { main: "#366D5A" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          textTransform: "none",
          minWidth: 150,
        },
      },
    },
  },
});

const categoryTypes = [
  "Stay informed",
  "Prevent or fix issues",
  "Plan for change",
];

const MessageCenterRouteHandler: React.FC = () => {
  const { param } = useParams<Params>();

  if (!param) {
    // Handle the case where param is undefined, for example by redirecting to a fallback route
    return <Redirect url={"https://changepilot.cloud"} />;
  }

  if (param.toUpperCase().startsWith("MC")) {
    return <MessageCenterDetail />;
  } else if (
    categoryTypes.some(
      (item: string) =>
        item.toLowerCase().replace(/ /g, "-") ===
        decodeURIComponent(param.toLowerCase()).replace(/ /g, "-")
    )
  ) {
    return <CategoryPage />;
  } else {
    return <ProductPage />;
  }
};

function App() {
  //const ChangePilotHomePage = "https://changepilot.cloud";
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div className="page-layout">page loading</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/:param" element={<MessageCenterRouteHandler />} />
          <Route path="/sitemapgen" element={<SitemapRouteHandler />} />
          <Route
            path="/settings/email"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={EmailSettings}
              />
            }
          />
          <Route
            path="/settings/tenant"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={TenantSettings}
              />
            }
          />
          {/* <Route path="/" element={<Redirect url={ChangePilotHomePage} />} /> */}
          {/* <Route path="/" element={<NotLoggedInPage />} /> */}
          <Route
            path="/changecalls"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={ChangeCallsPage}
              />
            }
          />
          <Route path="/beta" element={<DefaultPage />} />
          <Route path="/" element={<DefaultPage />} />
          <Route path="/all" element={<AllItemsPage />} />
          <Route path="/adminconsent" element={<AdminConsentLanding />} />
          <Route
            path="/profile"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => <ProfilePage />}
              />
            }
          />
          <Route
            path="/reports/messagecenter/all"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="All Items"
                    reportIntroText="All Message Center Items"
                    StdPageName={"5830e0cdd7851f1f87ac"}
                    RlsPageName={"c24251d8bcf19a9bf7bb"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/messagecenter/action"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="Actionable Items"
                    reportIntroText="Review items that are marked as 'Prevent or fix' or 'Plan for action'. "
                    StdPageName={"847589d0aa43d260791a"}
                    RlsPageName={"236ab90b6e34b02b4095"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/messagecenter/new"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="New Items"
                    reportIntroText="Review the latest items from the Message Center."
                    StdPageName={"d0e656f16720dd031753"}
                    RlsPageName={"465fea505007516dca6b"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/messagecenter/upcoming"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="Upcoming Items"
                    reportIntroText="Review items containing imminent updates and changes soon to deploy. Use the time-period buttons to see items starting to roll out in the next 30, 60, 90 or 180 days."
                    StdPageName={"6d6cc81ac297109b4124"}
                    RlsPageName={"971b23a47c08a10b552e"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/messagecenter/highadmin"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="High Admin Items"
                    reportIntroText="Review items that have been marked as 'High Admin Impact' and require attention from IT administrators."
                    StdPageName={"fc65d4eb14dd2ada5761"}
                    RlsPageName={"6f0bcc32ae1aa301cc9e"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/messagecenter/highuser"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="High User Items"
                    reportIntroText="Review items that have been marked as 'High User Impact', where the affecting update changes the user experience of an app, service or product."
                    StdPageName={"c7a78b4facc020e94d7b"}
                    RlsPageName={"5189608b685bb76c32bc"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/messagecenter/preview"
            element={
              <AuthenticationGuard
                requiresMembership={true}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="aa75c76e-d56d-427f-8b6d-54741217b28b"
                    RlsReportId="328fad1f-ef7d-4050-8e7c-2e0c2928b244"
                    reportTitle="Items with Preview Dates"
                    reportIntroText="Review items containing updates and changes that can be previewed before general release by participating users and accounts."
                    StdPageName={"8d28723ad2e8e869d067"}
                    RlsPageName={"45f7d259545d0d0db074"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/roadmap/all"
            element={
              <AuthenticationGuard
                requiresMembership={false}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    RlsReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    reportTitle="All Items"
                    reportIntroText="All roadmap items ordered by most recently added."
                    StdPageName={"951bb623594c0d9c4860"}
                    RlsPageName={"951bb623594c0d9c4860"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/roadmap/new"
            element={
              <AuthenticationGuard
                requiresMembership={false}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    RlsReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    reportTitle="New Items"
                    reportIntroText="New items recently added to the Roadmap. Use the time-period buttons to see items added in the last 7, 30, or 90 days."
                    StdPageName={"1e8c5c5babde87a05e6b"}
                    RlsPageName={"1e8c5c5babde87a05e6b"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/roadmap/dates"
            element={
              <AuthenticationGuard
                requiresMembership={false}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    RlsReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    reportTitle="Date Changes"
                    reportIntroText="Items that have had their rollout date updated."
                    StdPageName={"62b1a6c41cdbc4988e6c"}
                    RlsPageName={"62b1a6c41cdbc4988e6c"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/roadmap/rollingout"
            element={
              <AuthenticationGuard
                requiresMembership={false}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    RlsReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    reportTitle="Rolling Out Soon"
                    reportIntroText="Items that will shortly be rolling out. Use the time-period buttons to see items rolling out in this month, next month or next quarter."
                    StdPageName={"2101bdcebb534381c143"}
                    RlsPageName={"2101bdcebb534381c143"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/roadmap/launched"
            element={
              <AuthenticationGuard
                requiresMembership={false}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    RlsReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    reportTitle="Recently Launched"
                    reportIntroText="These items have been marked as 'launched' on the Roadmap."
                    StdPageName={"8cedf8299e1780509554"}
                    RlsPageName={"8cedf8299e1780509554"}
                  />
                )}
              />
            }
          />
          <Route
            path="/reports/roadmap/removed"
            element={
              <AuthenticationGuard
                requiresMembership={false}
                component={() => (
                  <PowerBIReportContainerPage
                    StdReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    RlsReportId="93adb765-edeb-4706-b51a-8e90dbaa51ad"
                    reportTitle="Removed Items"
                    reportIntroText="These items have been marked as 'cancelled' and subsequently removed from the Roadmap."
                    StdPageName={"cf5a80b2d6a79e42c773"}
                    RlsPageName={"cf5a80b2d6a79e42c773"}

                   
                  />
                )}
              />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
