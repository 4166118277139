import React from "react";

import { MessageCenterTableItem } from "../Models/MessageCenterTableItem";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component

import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import { themeBalham } from "ag-grid-community";
import { GridOptions, ICellRendererParams } from "ag-grid-community";

ModuleRegistry.registerModules([AllCommunityModule]);

interface props {
  items: MessageCenterTableItem[];
}

const MessageCenterTable = (props: props) => {
  const gridTheme = themeBalham.withParams({
    headerHeight: 30,
    headerTextColor: "black",
    rangeSelectionBorderColor: "rgb(60, 188, 0, 0.0)",
  });
  const rowHeight = 70;

  const ImpactRenderer: React.FC<ICellRendererParams> = (props) => {
    if (props.value === 1) {
      return <span>Low 🟢</span>;
    } else if (props.value === 2) {
      return <span>Medium 🟠</span>;
    } else if (props.value === 3) {
      return <span>High 🔴</span>;
    } else {
      return <span>Unknown</span>;
    }
  };

  const DateRenderer: React.FC<ICellRendererParams> = (props) => {
    const date = new Date(props.value);

    // Format the date using the user's locale
    const formattedDate = date.toLocaleDateString();
    return <span>{formattedDate}</span>;
  };

  const ServiceRenderer: React.FC<ICellRendererParams> = (props) => {
    const url = "/" + props.value.replace(/ /g, "-").toLowerCase();

    // Format the date using the user's locale

    return <a href={url}>{props.value}</a>;
  };

  const MCIDRenderer: React.FC<ICellRendererParams> = (props) => {
    const url = "/" + props.value.replace(/ /g, "-").toLowerCase();

    // Format the date using the user's locale

    return <a href={url}>{props.value}</a>;
  };

  const columnDefs: GridOptions["columnDefs"] = [
    {
      headerName: "MCID",
      field: "id",
      width: 100,
      cellRenderer: MCIDRenderer,
      filter: "agTextColumnFilter",
      sortable: true,
      headerTooltip: "Unique Microsoft Message Center ID number",
    },

    {
      field: "title",
      wrapText: true,
      minWidth: 200,
      flex: 1,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      field: "primaryService",
      wrapText: true,
      wrapHeaderText: true,
      width: 110,
      cellRenderer: ServiceRenderer,
      headerTooltip: "The main service impacted by this change",
    },
    {
      field: "adminImpact",
      width: 100,
      cellRenderer: ImpactRenderer,
      wrapHeaderText: true,
      sortable: true,
      headerTooltip:
        "High, config/decision required, Medium, be aware/optional change, Low, no action",
    },
    {
      field: "userImpact",
      width: 100,
      cellRenderer: ImpactRenderer,
      wrapHeaderText: true,
      sortable: true,
      headerTooltip:
        "High, communicate to users, Medium, optional to communicate, Low, no communication to users needed",
    },
    {
      field: "lastUpdated",
      headerName: "Posted / Updated",
      cellRenderer: DateRenderer,
      width: 140,
      sortable: true,
      filter: "agDateColumnFilter",
      headerTooltip: "Date Message was released and/or updated by Microsoft",
    },
    {
      field: "standardStartDate",
      headerName: "Release Start",
      cellRenderer: DateRenderer,
      width: 140,
      sortable: true,
      filter: "agDateColumnFilter",
      headerTooltip:
        "Date change should start coming to first tenants, rollouts typically take 1 month+ to reach all tenants",
    },
  ];

  return (
    <div
      className="ag-theme-balham"
      style={{
        height: "100%",
        width: "100%",
        minHeight: "100%",
        marginTop: "10px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={props.items}
        theme={gridTheme}
        rowHeight={rowHeight}
        suppressRowHoverHighlight={true}
        suppressRowDrag={true}
        columnHoverHighlight={false}
        domLayout="autoHeight"
        tooltipShowDelay={0}
      />
    </div>
  );
};

export default MessageCenterTable;
